import { Fragment, useEffect, useState, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Config from '../../../helpers/Config.js';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import { formatCurrency, makeProductShortDescription } from '../../../helpers/ConversionHelper';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import Seo from '../../components/shared/Seo';
import { useChangeQuantityMutation, useGetCartDataQuery, useRemoveCartItemMutation, useCalculateShippingQuery } from '../../../services/cart';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import LoadingScreen from '../../components/shared/LoadingScreen';
import ContentLoader from 'react-content-loader';
import MiniEditModal from '../products/MiniEditModal';
import { Alert, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import VASTemplateV2 from '../../components/products/VASTemplateV2';
import { useGetPaymentMethodsQuery, useGetShippingMethodsQuery } from '../../../services/checkout';
import { CARD_MAPPING } from '../../../helpers/Constants';
import _ from 'lodash';
import { ToggleContext } from '../../../ToggleProvider';

export const QuantityInput = ({ item, setIsCartChangeQuantityLoading, disabled, quantityError, setQuantityError }) => {
    const dispatch = useAppDispatch();
    const [changeQuantity, { isLoading }] = useChangeQuantityMutation();
    const [currentQuantity, setCurrentQuantity] = useState(item.quantity);
    const [maxQuantity, setMaxQuantity] = useState(300);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);

    useEffect(() => {
        setIsCartChangeQuantityLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setCurrentQuantity(item.quantity);
    }, [item.quantity]);

    const setOptimisticQuantity = (e) => {
        const newQuantity = parseInt(e.target.value);
        if (newQuantity > 0 && newQuantity < 1000) {
            setCurrentQuantity(newQuantity);
        }
    };

    const spreadQtyError = (id, message) => {
        setQuantityError({
            ...quantityError,
            [id]: message
        });
    };

    const handleChangeQuantity = async (productId, lineItemID, newLineQuantity, embroidery, embroideryPrice, itemClass, DepartmentId,sizingTemplate ) => {
        spreadQtyError(lineItemID, null);
        if (newLineQuantity == 0) {
            setCurrentQuantity(item.quantity);
            spreadQtyError(lineItemID, "Quantity cannot be less than 1. Remove the item instead");
            return;
        }
        try {
            await changeQuantity({
                productId,
                lineItemID,
                newLineQuantity,
                embroideryString: JSON.stringify(embroidery),
                embroideryPrice,
                itemClass,
                DepartmentId,
                sizingTemplate
            }).unwrap().then((res) => {
                dispatch(rootAction.cartActions.setCart(res));
            }).catch((err) => {
                setCurrentQuantity(item.quantity);
                spreadQtyError(lineItemID, err.data.message);
                if (err.data.message.match(/\d+/)) {
                    var strQty = err.data.message.match(/\d+/)[0];
                    setMaxQuantity(Number(strQty));
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <InputGroup className="custom-quantity custom-quantity-sm">
                <InputGroupAddon addonType="prepend">
                    <Button
                        className="quantity-decrease"
                        disabled={disabled || item.quantity - 1 === 0}
                        onClick={(e) => { e.target.blur(); handleChangeQuantity(item.productId, item.lineItemId, item.quantity - 1, item.embroidery, item.embroideryPrice, item.itemClass, item.DepartmentId, userSizingTemplate) }}
                    >
                    </Button>
                </InputGroupAddon>
                <Input
                    min={1}
                    max={3}
                    value={currentQuantity}
                    disabled={disabled}
                    onChange={setOptimisticQuantity}
                    onKeyUp={(e) => { if (e.key === "Enter") e.target.blur(); }}
                    onBlur={(e) => { if (currentQuantity !== item.quantity) handleChangeQuantity(item.productId, item.lineItemId, e.target.value, item.embroidery, item.embroideryPrice, item.itemClass, item.DepartmentId, userSizingTemplate) }}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        className="quantity-increase"
                        disabled={disabled || item.quantity === maxQuantity}
                        onClick={(e) => { e.preventDefault(); e.target.blur(); handleChangeQuantity(item.productId, item.lineItemId, item.quantity + 1, item.embroidery, item.embroideryPrice, item.itemClass, item.DepartmentId, userSizingTemplate); }}
                    >
                    </Button>
                </InputGroupAddon>
            </InputGroup>
            {quantityError && quantityError[item.lineItemId] &&
                <div className='invalid-feedback'>
                    {quantityError[item.lineItemId]}
                </div>}
        </>
    )
}

const Cart = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const [errorMessage] = useState(searchParams.get('WebError') || null);
    const [quantityError, setQuantityError] = useState({});

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [productSelectedAttributes,] = useState([]);
    const [showProductVariantsPopup, setShowProductVariantsPopup] = useState(false);
    const LocalizationLabelsArray = [];
    const checkoutActivity = useSelector((state) => state.checkout);
    const { billingAddress, shippingAddress, shippingMethod, hasSelectPaymentOptions, selectedPaymentMethod } = useSelector((state) => state.checkout);
    const { isLoading: isCartLoading, isFetching: isCartFetching } = useGetCartDataQuery(null);
    const currentCart = useSelector((state) => state.cartReducer);
    const [modalPayload, setModalPayload] = useState({});

    const { isShippingTaxExempt, isTaxExemptAppliedToMaxItem } = useSelector((state) => state.commonReducer);    const showAdjusted = currentCart.adjustedTotal != currentCart.orderTotal;

    const [hidden, setHidden] = useState({});
    const [removeFromCart, { isLoading: isRemoveFromCartLoading }] = useRemoveCartItemMutation();
    const [isCartChangeQuantityLoading, setIsCartChangeQuantityLoading] = useState(false);
    const { data: paymentMethods, isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery();
    const loginUser = useSelector(state => state.userReducer.user);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const [sizingTotals, setSizingTotals] = useState(null);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);
    const [inlineMessage, setInlineMessage] = useState('');
    const [inlineSuccess, setInlineSuccess] = useState(true);

    const styles = {
        popup: {
            display: showProductVariantsPopup ? "block" : "none",
            paddingRight: '16px'
        }
    };

    const { data: shippingMethods, isLoading: isShippingMethodsLoading, isFetching: isShippingMethodsFetching } = useGetShippingMethodsQuery(null, {
        refetchOnMountOrArgChange: true
    });
    useCalculateShippingQuery({
        billingAddress,
        shippingAddress,
        shippingId: shippingMethod?.id || shippingMethods?.default_shipping_method_id,
        additionalInfo: {
            isShippingTaxExempt, 
            isTaxExemptAppliedToMaxItem,
            selectedPaymentMethod,
            hasSelectPaymentOptions
        }
    }, {
        skip: isShippingMethodsFetching,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (!isShippingMethodsFetching && !isCartFetching) {
            const currentShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => sm.id && shippingMethod?.id === sm.id);
            const defaultShippingMethod = shippingMethods?.applicable_shipping_methods.find((sm) => sm.id === shippingMethods?.default_shipping_method_id);
            dispatch(rootAction.checkoutActions.setShippingMethod(currentShippingMethod || defaultShippingMethod));
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod(''));
            dispatch(rootAction.checkoutActions.setAddressActivity({
                ...checkoutActivity,
                shipToLocations: [],
                allotmentAddresses: []
            }));
        }
    }, [isShippingMethodsFetching, isCartFetching]);

    useEffect(() => {
        if (errorMessage !== null) {
            searchParams.delete('WebError');
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!isPaymentMethodFetching && currentCart && loginUser) {
            dispatch(rootAction.userAction.isOnAccountCapable({
                paymentMethods,
                isOnAccount: loginUser?.IsOnAccount
            }));
        }
    }, [isPaymentMethodFetching, currentCart, loginUser]);

    const closeProductVariantPopup = () => {
        setShowProductVariantsPopup(false);
    }

    const handleRemove = async (e, product) => {
        e.preventDefault();

        try {
            const newCart = await removeFromCart(product.lineItemId).unwrap();
            dispatch(rootAction.cartActions.setCart(newCart));
        }
        catch (err) {
            console.error(err);
        }
    };

    const toggleHide = idx => {
        setHidden({ ...hidden, [idx]: !hidden[idx] });
    };

    const LeftSkelly = () => (
        <ContentLoader width={925} height={580} viewBox="0 0 925 580">
            <rect x="3" y="3" rx="0" ry="0" width="1000" height="57" />
            <rect x="545" y="82" rx="0" ry="0" width="96" height="0" />
            <rect x="5" y="75" rx="0" ry="0" width="1000" height="353" />
            <rect x="625" y="435" rx="0" ry="0" width="300" height="59" />
        </ContentLoader>
    )
    const RightSkelly = () => (
        <ContentLoader width={450} height={580} viewBox="0 0 450 580">
            <rect x="3" y="75" rx="0" ry="0" width="450" height="353" />
        </ContentLoader>
    )

    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
        if (!isEditModalOpen) {
            dispatch(rootAction.pdpActions.reset());
        }
    }

    const handleEditCartProduct = (product) => {
        if (!product.isOrderable)
            return;

        setModalPayload(product);
        setIsEditModalOpen(true);
    }

    useEffect(() => {
        if(isCartLoading) return;
        if (currentCart?.products?.length > 0) {
            const groupedByCategory = currentCart?.products?.reduce((accumulator, item) => {
                const { itemClass, quantity } = item;

                if (!accumulator[itemClass]) {
                    accumulator[itemClass] = { items: [], total: 0, allowed: 0 };
                }

                accumulator[itemClass].items.push(item);
                accumulator[itemClass].total += quantity;
                try {
                    accumulator[itemClass].allowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClass == itemClass)?.MaxQuantityPerOrder || 0;
                } catch (error) {
                    accumulator[itemClass].allowed = 0;
                }

                return accumulator;

            }, {});

            if (isToggled) {
                let updatedData = (groupedByCategory && Object.keys(groupedByCategory).length > 0) ? groupedByCategory : {};
                try {
                    for (let i = 0; i < userSizingTemplate?.SizingTemplates[0]?.Quotas?.length; i++) {
                        if (!Object.keys(updatedData).includes(userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass)) {
                            updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass] = { items: [], total: 0, allowed: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder };
                        }
                    }
                    const totalAllowed =  Object.values(updatedData).reduce((acc, item) => acc + item.allowed, 0);
                    const totalAdded =  Object.values(updatedData).reduce((acc, item) => acc + item.total, 0);
                    setSizingTotals({totalAdded, totalAllowed});
                    if (totalAdded - totalAllowed === 0) {
                        setInlineMessage('');
                        setInlineSuccess(true);
                    } else {
                        setInlineMessage('You have one or more product credits remaining. Please use all remaining credits before proceeding to checkout.');
                        setInlineSuccess(false);
                    }
                } catch (error) {

                }
            } else {
                setInlineSuccess(true);
            }
        }
    }, [currentCart, isCartFetching]);

    const [isMobile, setMobile] = useState(window.innerWidth < 992);
    const updateMmobileView = () => {
        setMobile(window.innerWidth < 992);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMmobileView);
        return () => window.removeEventListener("resize", updateMmobileView);
    });

    return (
        <>
            {isEditModalOpen && <MiniEditModal open={isEditModalOpen} toggle={toggleEditModal} cartProduct={modalPayload} />}
            <LoadingScreen loading={isRemoveFromCartLoading || isCartChangeQuantityLoading} />
            <Seo
                title="Cart"
                description="Cart"
                keywords="Cart"
            />

            <SiteBreadcrumb title="Cart" />

            <section className={`cart-area mt-0 mb-8 ${isToggled && !inlineSuccess && isMobile ? 'sticky-checkout' : ''}`}>
                <div className="container">
                    {errorMessage !== null &&
                        <Alert color="danger" className="mb-4">
                            {errorMessage}
                        </Alert>}
                    <div className="row">
                        {(isCartLoading || isCartFetching) &&
                            <>
                                <div className='col-12 col-md-8'>
                                    <LeftSkelly />
                                </div>
                                <div className='col-md-4'>
                                    <RightSkelly />
                                </div>
                            </>}
                        {!isCartLoading && !isCartFetching && currentCart.products?.length > 0 &&
                            <div className="col-12">
                                <div className="col-12 p-0">
                                    <div className="align-items-center justify-content-between">
                                        <h1 className="page-title px-0">
                                            <span>{LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Shopping Bag", "lbl_hdr_shopping_bag")
                                                :
                                                "Shopping Bag"
                                            }</span>
                                            ({currentCart.totalCartItems})
                                        </h1>
                                    </div>
                                </div>
                                <form onSubmit={e => { e.preventDefault(); }}></form >
                                <div className="row">
                                    <div className="col-12 col-md-8">
                                        <div className='card card-font-1'>
                                            <div className='card-body p-0'>
                                                <div className='d-md-flex d-none p-3 font-weight-bold fw-bold'>
                                                    <div className='col-3 col-md-2 p-0'>
                                                        <span id="lbl_hdr_cart_prod">{"Product"}</span>
                                                    </div>
                                                    <div className="col-9 col-md-10 pr-0 pe-0">
                                                        <div className='row'>
                                                            <div className='col-12 col-md-5 pb-2 pb-md-0' id="lbl_hdr_cart_name"></div>
                                                            <div className="col-12 col-md-7">
                                                                <div className="row">
                                                                    {!isToggled &&
                                                                        <div className="col text-center">
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_hdr_cart_price")
                                                                                :
                                                                                "Price"
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="col-4 text-center">
                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Qty", "lbl_hdr_cart_qty")
                                                                            :
                                                                            "Qty"
                                                                        }
                                                                    </div>
                                                                    {!isToggled &&
                                                                        <div className="col total text-right text-end">
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Total", "lbl_hdr_cart_totl")
                                                                                :
                                                                                "Total"
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul className='card-ul'>
                                                    {currentCart.products?.map((item, idx) => (
                                                        <li className={`border-top mb-0 p-3 product-info ${!item.isOrderable ? "disabled" : ""}`} key={item.lineItemId}>
                                                            <div className='col-12 px-1 px-md-4'>
                                                                <div className='row'>
                                                                    <div className='col-3 col-md-2 p-0'>
                                                                        <div className="align-items-center d-flex item-image justify-content-center product-img">
                                                                            <Link to={`/${getLanguageCodeFromSession()}/product-detail/${encodeURIComponent(item.productId)}/${item.DepartmentId}/${_.kebabCase(item.productName)}`} >
                                                                                <img src={item.images[0]?.url.replace('?sw=100&sh=150&sm=fit', '?sw=162&sh=241&sm=fit')} alt="image" className='product-image' />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 col-md-10 pr-0 pe-0">
                                                                        <div className='row'>
                                                                            <div className='col-12 col-md-5 pb-2 pb-md-0' id="lbl_hdr_cart_name">
                                                                                <div className='card-link line-item-name mb-1 w-100'>
                                                                                    <Link to={`/${getLanguageCodeFromSession()}/product-detail/${encodeURIComponent(item.productId)}/${item.DepartmentId}/${_.kebabCase(item.productName)}?fromCart=true`} >
                                                                                        {
                                                                                            makeProductShortDescription(item.productName, 80)
                                                                                        }
                                                                                    </Link>
                                                                                </div>
                                                                                <div className='w-100 font-size-p875'>
                                                                                    Style #: {item.productId}
                                                                                </div>
                                                                                {
                                                                                    item.colorName != undefined && item.colorName != ""
                                                                                        ?
                                                                                        <div className='w-100 font-size-p875'>
                                                                                            <span>Color:</span> {item.ColorLongName}
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                }

                                                                                {
                                                                                    item.sizeShortName != undefined && item.sizeShortName != ""
                                                                                        ?
                                                                                        <div className='w-100 font-size-p875'>
                                                                                            <span>Size:</span> {item.sizeShortName.replace('_', '/')}
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                }
                                                                                {
                                                                                    item.SelectedEmployee
                                                                                        ?
                                                                                        <div className='w-100 font-size-p875'>
                                                                                            <span className='label'>Employee:</span> {item.SelectedEmployee.replace(',', ' ')}
                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                {
                                                                                    item.availabilityMessage
                                                                                        ?
                                                                                        <>
                                                                                            <div className='w-100 font-size-p875'>
                                                                                                {item.availabilityMessage}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <div className='w-100 d-none d-md-block mt-3'>
                                                                                    {!isToggled && 
                                                                                        <Fragment>
                                                                                            <span className='card-link font-size-p875 underline-link'>
                                                                                                <Link to='#' onClick={(e) => { e.preventDefault(); handleEditCartProduct(item); }} >
                                                                                                    Edit
                                                                                                </Link>
                                                                                            </span>

                                                                                            <span className="mx-1 divider-top">|</span>
                                                                                        </Fragment>
                                                                                    }

                                                                                    <span className='card-link font-size-p875 underline-link'>
                                                                                        <Link to="#" onClick={(e) => { e.preventDefault(); handleRemove(e, item); }}>
                                                                                            Remove
                                                                                        </Link>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-7 mt-3 mt-md-0 d-none d-md-block">
                                                                                <div className="row">
                                                                                    {!isToggled ?
                                                                                        <>
                                                                                            <div className='col-4 d-block d-md-none font-weight-bold my-2 my-md-0'>
                                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_hdr_cart_price")
                                                                                                    :
                                                                                                    "Price"
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-8 col-md font-weight-bold my-2 my-md-0 text-md-center text-right word-break">
                                                                                                {item.skuOnClearance ?
                                                                                                    <span className="text-danger">{formatCurrency((item.discountedPrice / item.quantity) - item.embroideryPrice)}/ea</span>
                                                                                                    :
                                                                                                    <>
                                                                                                        <span className="text-danger">{formatCurrency((item.discountedPrice / item.quantity) - item.embroideryPrice)}/ea</span><br />
                                                                                                        {item.regularPrice > 0 && <span style={{ textDecoration: "line-through" }}>{formatCurrency(item.regularPrice)}</span>}
                                                                                                    </>}
                                                                                            </div>
                                                                                        </> : null}
                                                                                    <div className='col-4 d-block d-md-none font-weight-bold my-2 my-md-0'>
                                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Qty", "lbl_hdr_cart_qty")
                                                                                            :
                                                                                            "Qty"
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-8 col-md-4 my-2 my-md-0 text-md-center text-right">
                                                                                        <QuantityInput item={item} setIsCartChangeQuantityLoading={setIsCartChangeQuantityLoading} disabled={!item.isOrderable} quantityError={quantityError} setQuantityError={setQuantityError} />
                                                                                    </div>
                                                                                    {!isToggled ? <>
                                                                                        <div className='col-4 d-block d-md-none font-weight-bold my-2 my-md-0'>
                                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Total", "lbl_hdr_cart_totl")
                                                                                                :
                                                                                                "Total"
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-8 col-md font-weight-bold my-2 my-md-0 text-end text-right total">
                                                                                            {<span>{formatCurrency(item.productPrice)}</span>}
                                                                                        </div>
                                                                                    </> : <></>
                                                                                    }
                                                                                    <div className='col-12 d-flex justify-content-between mt-3'>
                                                                                        <span className={`px-1 font-weight-bold ${!item.isOrderable ? "alert-danger" : ""}`}>
                                                                                            {!item.isOrderable ? Config.ERROR_MESSAGES.CART_PRODUCT_NOT_AVAILABLE : ""}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                            {item.embroidery?.length > 0 ?
                                                                                <>
                                                                                <div className='col-12 p-0 mt-4'>
                                                                                    <div className='row'>
                                                                                        <div className='col-6 col-md-5 pb-md-0'>
                                                                                            <div className='align-items-center card-link d-flex font-weight-bold font-size-p875 text-uppercase w-100'>
                                                                                                <span className="d-md-inline-block d-none icon icon-embroideryNeedle mr-1"></span>
                                                                                                <div className='d-md-none'>Embroidery</div>
                                                                                                <div className="align-items-center d-none d-md-flex tooltip-box">
                                                                                                    Embroidery added
                                                                                                    <span className="tooltip-icon ml-1"></span>
                                                                                                    <div className="fade tooltip">
                                                                                                        <div className="arrow"></div>
                                                                                                        <div className="tooltip-inner">Embroidery details listed below.</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 col-md-7">
                                                                                            {!isToggled&&
                                                                                                <div className="row">
                                                                                                    <div className="col d-md-block d-none font-weight-bold text-center word-break">
                                                                                                        {formatCurrency(item.embroideryPrice)}
                                                                                                    </div>
                                                                                                    <div className="col-4 text-center"></div>
                                                                                                    <div className="col total text-right text-end font-weight-bold">
                                                                                                        {formatCurrency(item.embroideryPrice * item.quantity)}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div className='col-12 d-flex flex-wrap mb-md-0 my-3'>
                                                                                            <div className='col col-md-5 p-0'>
                                                                                                <span onClick={e => toggleHide(idx)} className={`embroidery_details ${!hidden[idx] ? "active" : ""}`}>
                                                                                                    <span className='expand_div'>
                                                                                                        <span>Show Embroidery Preview</span>
                                                                                                    </span>
                                                                                                    <span className='collapse_div'>
                                                                                                        <span>Hide Embroidery Preview</span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                            {!isToggled&&
                                                                                            <div className="col-12 col-md-7 mb-4 mb-md-0 p-0">
                                                                                                <div className='col-12 d-flex justify-content-end p-0'>
                                                                                                    <span className='card-link font-size-p875 underline-link'>
                                                                                                        { item.isOrderable ?
                                                                                                            <Link to={`/${getLanguageCodeFromSession()}/product-detail/${encodeURIComponent(item.productId)}/${item.DepartmentId}/${_.kebabCase(item.productName)}?VASTemplateId=${item.EmbTemplateId}&VASDescriptor=${JSON.stringify(item.embroidery)}&LineItemId=${item.lineItemId}&LineQty=${item.quantity}&selectedEmployee=${item.SelectedEmployee}&edit=true`} >
                                                                                                                Edit
                                                                                                            </Link>
                                                                                                            : 
                                                                                                            <Link to="/cart" >
                                                                                                                Edit
                                                                                                            </Link>
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>}
                                                                                        </div>
                                                                                        <div className='col-12 EmbSelection'>
                                                                                            {!!hidden[idx] && (
                                                                                                <VASTemplateV2
                                                                                                    vasData={item.embroidery}
                                                                                                    VasSelectionIndex={0}
                                                                                                    ItemChecked={false}
                                                                                                    PreviewBaseUrl={item.EmbPreviewBaseUrl}
                                                                                                    hexCode={item.HexCode}
                                                                                                    readOnly={true}
                                                                                                    itemId={item.lineItemId}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                </> : <></>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* mobile/tablet only */}
                                                            <div className="d-block d-md-none mt-3">
                                                                <div className="row">
                                                                    <div className='col-4 font-weight-bold align-content-center'>
                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "QTY", "lbl_hdr_cart_qty")
                                                                            :
                                                                            "QTY"
                                                                        }
                                                                    </div>
                                                                    <div className="col-8 text-right">
                                                                        <QuantityInput item={item} disabled={!item.isOrderable} setIsCartChangeQuantityLoading={setIsCartChangeQuantityLoading} quantityError={quantityError} setQuantityError={setQuantityError} />
                                                                    </div>
                                                                    {!item.isOrderable &&
                                                                        <div className="col-12 my-2 text-center">
                                                                            <span className="alert-danger px-1 font-weight-bold">
                                                                                {Config.ERROR_MESSAGES.CART_PRODUCT_NOT_AVAILABLE}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {!isToggled &&
                                                                    <>
                                                                        <div className='col-4 font-weight-bold my-2'>
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "PRICE", "lbl_hdr_cart_price")
                                                                                :
                                                                                "PRICE"
                                                                            }
                                                                        </div>
                                                                        <div className="col-8 font-weight-bold my-2 text-right word-break">
                                                                            {item.skuOnClearance ?
                                                                                <span className="text-danger">{formatCurrency((item.discountedPrice / item.quantity) - item.embroideryPrice)}/ea</span>
                                                                                :
                                                                                <>
                                                                                    <span className='mr-1' style={{ textDecoration: "line-through" }}>{formatCurrency(item.regularPrice)}</span>
                                                                                    <span className="text-danger mr-1">{formatCurrency((item.discountedPrice / item.quantity) - item.embroideryPrice)}/ea</span>
                                                                                </>}
                                                                        </div>
                                                                        <div className="d-md-inline borderDiv"></div>
                                                                        <div className='col-4 font-weight-bold my-2'>
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "TOTAL", "lbl_hdr_cart_totl")
                                                                                :
                                                                                "TOTAL"
                                                                            }
                                                                        </div>
                                                                        <div className="col-8 font-weight-bold my-2 text-right total">
                                                                            {<span>{formatCurrency(item.productPrice)}</span>}
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    <div className='col-12 d-flex mt-3'>
                                                                        {!isToggled && 
                                                                            <Fragment>
                                                                                <span className='card-link font-size-p875 underline-link'>
                                                                                    <Link to='#' onClick={(e) => { e.preventDefault(); handleEditCartProduct(item); }} >
                                                                                        Edit
                                                                                    </Link>
                                                                                </span>

                                                                                <span className="mx-1 divider-top">|</span>
                                                                            </Fragment>
                                                                        }
                                                                        <span className='card-link font-size-p875 underline-link'>
                                                                            <Link to="#" onClick={(e) => { e.preventDefault(); handleRemove(e, item); }}>
                                                                                Remove
                                                                            </Link>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end my-5 px-0">
                                            <Link to={isToggled &&  localStorage.getItem('navSoleLink') && localStorage.getItem('navSoleLink').length>5 ? localStorage.getItem('navSoleLink') : "/${getLanguageCodeFromSession()}/"} className="btn btn-outline-primary w-100 w-m-auto">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Continue Shopping", "lbl_cart_cont_shop")
                                                    :
                                                    "Continue Shopping"
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-4 totals mb-9 mb-md-5">
                                        <div className="cart-order-sticky">
                                            <div className={`cart-order-summary sidebar-card ${isToggled && isMobile ? 'bg-transparent p-0' : ''}`}>
                                                {!isToggled ? <><Fragment>
                                                    <div className="order-summary-title card-header-custom">
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Order Summary", "lbl_cart_total")
                                                            :
                                                            "Order Summary"
                                                        }
                                                    </div>
                                                </Fragment></> : <>{!isMobile&&
                                                    <div className="order-summary-title card-header-custom">
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Checkout Now", "lbl_cart_total")
                                                            :
                                                            "Checkout Now"
                                                        }
                                                    </div>  }</>
                                                }
                                                <div className={`sidebar-card__body ${isToggled && isMobile ? 'border-0 m-0 p-0' : ''}`}>
                                                    {!isToggled ? <><Fragment>
                                                        <div className="order-summary-costs-list">

                                                            <div className="row total-item">
                                                                <div className="col-8">
                                                                    <p className="order-receipt-label"><span>
                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Item Total", "lbl_cart_total")
                                                                            :
                                                                            "Item Total"
                                                                        }
                                                                    </span></p>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <p className="text-right"><span className="order-total">{formatCurrency(currentCart.cartSubTotal + currentCart.savingsTotal)}</span></p>
                                                                </div>
                                                            </div>
                                                            {currentCart.savingsTotal > 0 ? <Fragment>
                                                                <div className="row total-item">
                                                                    <div className="col-8">
                                                                        <p className="order-receipt-label">   <span style={{ fontWeight: "bold" }} className="text-danger">
                                                                            Discounts
                                                                        </span></p>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <p className="text-right"><span style={{ fontWeight: "bold" }} className="text-danger">-{formatCurrency(currentCart.savingsTotal)}</span></p>
                                                                    </div>
                                                                </div>

                                                                <div className="row total-item">
                                                                    <div className="col-8">
                                                                        <p className="order-receipt-label"><span>
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Item Total", "lbl_cart_total")
                                                                                :
                                                                                "Item Subtotal"
                                                                            }
                                                                        </span></p>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <p className="text-right"><span className="order-total">{formatCurrency(currentCart.cartSubTotal)}</span></p>
                                                                    </div>
                                                                </div></Fragment> : <></>}

                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <p>{LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Cost", "lbl_cart_shipping")
                                                                        :
                                                                        "Shipping Cost"
                                                                    }</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-right shipping-cost">{formatCurrency(currentCart.shippingSubTotal)}</p>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <p>{LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Sales Tax", "lbl_cart_tax")
                                                                        :
                                                                        "Estimated Sales Tax"
                                                                    }</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="text-right tax-total">{formatCurrency(showAdjusted ? currentCart.adjustedTax : currentCart.taxTotal)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row no-gutters estimate-total">
                                                            <div className="col-8">
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Order Total", "lbl_cart_total_2")
                                                                    :
                                                                    "Estimated Order Total"
                                                                }
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="text-right grand-total m-0">{formatCurrency(showAdjusted ? currentCart.adjustedTotal : currentCart.orderTotal)}</div>
                                                            </div>
                                                        </div>
                                                        {currentCart.savingsTotal > 0 ? <Fragment>
                                                            <div className="row order-savings justify-content-center mb-1 mb-md-4 ">
                                                                <span style={{ fontWeight: "bold" }} className="text-danger">Your savings:</span> &nbsp; <span style={{ fontWeight: "bold" }} className="text-danger">{formatCurrency(currentCart.savingsTotal)}</span>
                                                            </div></Fragment> : <></>}
                                                    </Fragment></> : <></>}
                                                    <div className="row">
                                                        <div className="col-12 checkout-continue mt-4 pl-4 pr-4">
                                                            {inlineSuccess ? <>
                                                                <div style={{ color: "darkgreen", display: isToggled ? "block" : "none" }}>
                                                                    {inlineMessage}
                                                                </div>
                                                            </> : <>
                                                                <div style={{ color: "red", display: isToggled ? "block" : "none" }}>
                                                                    {inlineMessage}
                                                                </div><br/></>
                                                            }
                                                            <div className="mb-sm-3">
                                                                {inlineSuccess ? <>
                                                                <Link to={`/${getLanguageCodeFromSession()}/checkout`} className="btn btn-primary w-100">
                                                                    {LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "CHECKOUT", "lbl_cart_proc_check")
                                                                        :
                                                                        "CHECKOUT"
                                                                    }
                                                                </Link></>:<>
                                                                    <button disabled="true" className="btn btn-secondary btn-block w-100">CHECKOUT</button>
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                            {!isToggled&&
                                            <div className='card-link col-12 d-flex justify-content-center underline-link links-wrapper'>
                                                <Link to={`/${getLanguageCodeFromSession()}/contact-us`} id="lbl_thead_contct" className='m-3'>

                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Contact Us", "lbl_thead_contct")
                                                        :
                                                        "Contact Us"
                                                    }
                                                </Link>
                                                <Link to={`/${getLanguageCodeFromSession()}/returns`} id="lbl_thead_return_policy" className='m-3'>

                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Return Policy", "lbl_thead_return_policy")
                                                        :
                                                        "Return Policy"
                                                    }
                                                </Link>
                                            </div>
                                            }
                                            {
                                            !isPaymentMethodFetching && !loginUser.IsOnAccountCapable && paymentMethods?.applicable_payment_methods.length > 0 &&
                                                <div className="cards d-flex justify-content-center">
                                                    {paymentMethods.applicable_payment_methods.find(pm => pm.id === 'CREDIT_CARD')?.cards.map((card) => {
                                                        return (
                                                            <span key={card.name} className={`creditcard ${CARD_MAPPING[card.name.toLowerCase()] ?? card.name.toLowerCase()}`}></span>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div >
                                    </div>
                                </div>
                            </div>}
                        {!isCartLoading && !isCartFetching && (!currentCart.products || currentCart.products?.length == 0) &&
                            <div className="col-12 text-center">
                                <h3>Your Shopping Cart is Empty</h3>
                                <div className="col-12 mt-3">
                                    <Link to={`/${getLanguageCodeFromSession()}/`} className="btn btn-outline-primary">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Continue Shopping", "lbl_cart_cont_shop")
                                            :
                                            "Continue Shopping"
                                        }
                                    </Link>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>

            <div
                className="modal fade sizeGuideModal show" style={styles.popup}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                closeProductVariantPopup();

                            }}
                            className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
                                <i className="fas fa-times"></i>
                            </span>
                        </button>

                        <div className="modal-sizeguide">
                            <h3>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Product Variants", "lbl_cart_product_variant")
                                    :
                                    "Product Variants"
                                }
                            </h3>
                            <div className="text-align-left">
                                <ul className="list-group">
                                    {
                                        productSelectedAttributes?.map((item, idx) =>
                                            <li className="list-group-item">
                                                <h3 className="product-variant-title">{item.AttributeDisplayName}</h3>
                                                <div className="form-check form-check-inline"><label className="form-check-label" for="3Processor1">{item.PrimaryKeyDisplayValue}</label></div>

                                            </li>

                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Cart;
